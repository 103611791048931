import { Input, Modal, Form } from 'antd';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { api } from '@services/api';

export const ContragentsModal = ({
	isVisible = false,
	contragentId,
	closeModal,
	action,
	fetchContragents,
	checkContragents,
}) => {
	const title = action === 'create' ? 'Создать контрагента' : 'Редактировать контрагента';
	const [confirmLoading, setConfirmLoading] = useState(false);

	const [warn, setWarn] = useState(null);
	const findContragentById = (state) => {
		let contragent = state.contragents.contragents.find(({ id }) => id === contragentId);

		return Object.keys(contragent)
			.filter((key) => key !== 'id')
			.reduce((obj, key) => {
				obj[key] = contragent[key];
				return obj;
			}, {});
	};
	const contragentToUpdate = useSelector((state) => contragentId && findContragentById(state));
	const contragentToCreate = {
		name: '',
		unp: '',
		address: ``,
		mailAddress: ``,
		bank: '',
		billing: '',
		number: '',
		director: '',
	};

	const contragentForm = {
		create: contragentToCreate,
		update: contragentToUpdate,
	};
	const contragentActions = {
		create: async () => {
			await api.create('contragents', {
				...formState,
			});
		},
		update: async () => {
			await api.update('contragents', contragentId, {
				...formState,
			});
		},
	};

	let [formState, setFormState] = useState(contragentForm[action]);

	const handleOk = async () => {
		setConfirmLoading(true);
		if (formState.name.trim() && String(formState.unp).trim() && !Number.isNaN(+formState.unp)) {
			try {
				if (action === 'create') {
					const data = await checkContragents({ unp: +formState.unp });

					if (data.count === 0) {
						await contragentActions[action]();
						await fetchContragents();

						closeModal();
					} else {
						setWarn('Пользователь с таким УНП уже есть в базе');
					}
				} else {
					await contragentActions[action]();
					await fetchContragents();

					closeModal();
				}

				// closeModal();
			} catch (e) {
				console.log(e);
			} finally {
				setTimeout(() => setConfirmLoading(false), 600);
			}
		} else if (Number.isNaN(+formState.unp)) {
			setWarn('Введите корректный УНП');
			setTimeout(() => setConfirmLoading(false), 600);
		} else {
			setWarn('Заполните поля!');
			setTimeout(() => setConfirmLoading(false), 600);
		}
	};

	return (
		<Modal
			title={title}
			visible={isVisible}
			onOk={handleOk}
			confirmLoading={confirmLoading}
			onCancel={closeModal}
			okText="Сохранить"
			cancelText="Отмена"
		>
			{formState && (
				<Form id="table-form" layout="vertical">
					<Form.Item label="Юридическое название:">
						<Input
							value={formState.name}
							onChange={(e) => {
								setFormState((prev) => ({ ...prev, name: e.target.value }));
							}}
						/>
					</Form.Item>
					<Form.Item label="УНП (до 11 цифр):">
						<Input
							maxLength={11}
							value={formState.unp}
							onChange={(e) => {
								setFormState((prev) => ({ ...prev, unp: e.target.value }));
							}}
						/>
					</Form.Item>

					<Form.Item label="Адрес:">
						<Input.TextArea
							value={formState.address}
							onChange={(e) => {
								setFormState((prev) => ({ ...prev, address: e.target.value }));
							}}
						/>
					</Form.Item>
					<Form.Item label="Адрес для почты:">
						<Input.TextArea
							value={formState.mailAddress}
							onChange={(e) => {
								setFormState((prev) => ({ ...prev, mailAddress: e.target.value }));
							}}
						/>
					</Form.Item>
					<Form.Item label="Расчетный счет:">
						<Input
							value={formState.billing}
							onChange={(e) => {
								setFormState((prev) => ({ ...prev, billing: e.target.value }));
							}}
						/>
					</Form.Item>
					<Form.Item label="Банк:">
						<Input
							value={formState.bank}
							onChange={(e) => {
								setFormState((prev) => ({ ...prev, bank: e.target.value }));
							}}
						/>
					</Form.Item>
					<Form.Item
						label="Электронная почта:"
						name="email"
						// rules={[
						// 	{ type: 'email', message: 'Введите корректный email!' }
						// ]}
					>
						<Input
							value={formState.email}
							onChange={(e) => {
								setFormState((prev) => ({ ...prev, email: e.target.value }));
							}}
						/>
					</Form.Item>
					<Form.Item label="Номер телефона:">
						<Input
							value={formState.number}
							onChange={(e) => {
								setFormState((prev) => ({ ...prev, number: e.target.value }));
							}}
						/>
					</Form.Item>
					<Form.Item label="Директор:">
						<Input
							value={formState.director}
							onChange={(e) => {
								setFormState((prev) => ({ ...prev, director: e.target.value }));
							}}
						/>
					</Form.Item>

					{warn && <Form.Item>{warn}</Form.Item>}
				</Form>
			)}
		</Modal>
	);
};
