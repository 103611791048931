const SAVE_DATA = 'SAVE_DATA';
const CHANGE_DOCUMENT = 'CHANGE_DOCUMENT';
const UPDATE_PARAMS = 'UPDATE_PARAMS';
const FILTER_DOCUMENTS = 'FILTER_DOCUMENTS';

const initialState = {
	documents: [],
	contragents: [],
	companies: [],
	types: [
		{ name: 'Спецификация', id: 1, color: 'cyan' },
		{ name: 'Акт', id: 2, color: 'green' },
		{ name: 'Счет', id: 3, color: 'geekblue' },
		{ name: 'Договор', id: 4, color: 'red' },
		{ name: 'Иной', id: 5, color: 'orange' },
	],
	statuses: [
		{ name: 'В процессе', id: 1, color: 'geekblue' },
		{ name: 'Отправлен', id: 2, color: 'gold' },
		{ name: 'Закрыт', id: 3, color: 'green' },
		{ name: 'Новый', id: 4, color: 'volcano' },
	],
	params: {
		filter: null,
		sort: null,
		pagination: {
			page: 1,
			limit: 20,
		},
	},
};

export const documentsReducer = (state = initialState, action) => {
	switch (action.type) {
		case SAVE_DATA: {
			return { ...state, [action.dataKey]: action.data };
		}
		case CHANGE_DOCUMENT: {
			return {
				...state,
				documents: state.documents.map((doc) => {
					if (doc.id === action.document_id) {
						const changedDoc = { ...doc, [action.dataKey]: action.data };
						return changedDoc;
					}
					return doc;
				}),
			};
		}
		case UPDATE_PARAMS: {
			return { ...state, params: { ...state.params, [action.dataKey]: action.value } };
		}
		case FILTER_DOCUMENTS: {
			return {
				...state,
				params: {
					...state.params,
					query: {
						...state.queryParams.query,
						[action.dataKey]: action.value,
					},
				},
			};
		}
		default: {
			return state;
		}
	}
};

export const saveDocumentsAC = (dataKey, data) => ({ type: SAVE_DATA, dataKey, data });
export const changeDocumentAC = (dataKey, data, document_id) => ({ type: CHANGE_DOCUMENT, dataKey, data, document_id });
export const updateDocumentsParamsAC = (dataKey, value) => ({ type: UPDATE_PARAMS, dataKey, value });
