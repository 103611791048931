import { Input, Modal, Form, Button, Select, Tag, DatePicker } from 'antd';
import { useState } from 'react';
import moment from 'moment';
import { LinkOutlined } from '@ant-design/icons';
import { api } from '@services/api';
import { selectFilterOption } from '@services/utils';
import 'moment/locale/ru';
import locale from 'antd/es/date-picker/locale/ru_RU';
import TextArea from "antd/es/input/TextArea";

const { Option } = Select;

export const DocumentsModal = ({
	isVisible = false,
	document_id,
	closeModal,
	action,
	refreshDocuments,
	documentsState,
	...restProps
}) => {
	let [loading, setLoading] = useState(false);
	const title = action === 'create' ? 'Создать документ' : 'Редактировать документ';

	const findDocumentById = ({ documents }) => {
		let document = documents.find(({ id }) => id === document_id);

		return Object.keys(document)
			.filter((key) => key !== 'id')
			.reduce((obj, key) => {
				obj[key] = document[key];
				return obj;
			}, {});
	};

	const documentToUpdate = !!document_id && findDocumentById(documentsState);
	const documentToCreate = {
		companyId: 1,
		name: '',
		date: null,
		changeDate: null,
		creation_date: null,
		contragentId: null,
		type: 4,
		status: 4,
		link: '',
		total: '0.00',
		comment:''
	};

	const documentForm = {
		create: documentToCreate,
		update: documentToUpdate,
	};

	let [formState, setFormState] = useState(documentForm[action]);
	let [clicked, setClicked] = useState(false);

	const documentActions = {
		create: async () => {
			await api.create('documents', {
				...formState,
			});
		},
		update: async () => {
			await api.update('documents', document_id, {
				...formState,
			});
		},
	};

	const contragentsControl = action === 'create' && clicked && !formState.contragentId;

	const applyForm = async () => {
		setLoading(true);
		setClicked(true);
		if (formState.name.trim() && !!formState.contragentId && !!formState.date) {
			try {
				await documentActions[action]();
				await closeModal();
				await refreshDocuments();
			} catch (e) {
				console.log(e);
			} finally {
				setTimeout(() => setLoading(false), 600);
			}
		}
		setTimeout(() => setLoading(false), 600);
	};

	return (
		<Modal title={title} visible={isVisible} onCancel={closeModal} width="800px" footer={null}>
			<Form id="table-form" layout="vertical" onFinish={applyForm} className="documents-form">
				<Form.Item label="Компания:">
					<Select
						onChange={(value) => {
							setFormState((prev) => ({ ...prev, companyId: value }));
						}}
						optionFilterProp="children"
						filterOption={selectFilterOption}
						value={formState.companyId}
						optionLabelProp="label"
						suffixIcon={<i className="ant-menu-submenu-arrow" />}
					>
						{documentsState.companies.map(({ id, name, color = null }) => (
							<Option key={id} name={name} value={id} label={<Tag color={color}>{name}</Tag>}>
								<Tag color={color}>{name}</Tag>
							</Option>
						))}
					</Select>
				</Form.Item>

				<Form.Item label="Название документа:">
					<Input
						value={formState.name}
						className={clicked && !formState.name.trim() && 'error-field'}
						onChange={(e) => {
							setFormState((prev) => ({ ...prev, name: e.target.value }));
						}}
					/>
					{clicked && !formState.name.trim() && (
						<span className="error-form-control">Заполните название документа</span>
					)}
				</Form.Item>

				<Form.Item label="Тип документа:">
					<Select
						onChange={(value) => {
							setFormState((prev) => ({ ...prev, type: value }));
						}}
						optionFilterProp="children"
						filterOption={selectFilterOption}
						value={formState.type}
						optionLabelProp="label"
						suffixIcon={<i className="ant-menu-submenu-arrow" />}
					>
						{documentsState.types.map(({ id, name, color = null }) => (
							<Option key={id} name={name} value={id} label={<Tag color={color}>{name}</Tag>}>
								<Tag color={color}>{name}</Tag>
							</Option>
						))}
					</Select>
				</Form.Item>

				<Form.Item label="Статус документа:">
					<Select
						onChange={(value) => {
							setFormState((prev) => ({ ...prev, status: value }));
						}}
						optionFilterProp="children"
						filterOption={selectFilterOption}
						value={formState.status}
						optionLabelProp="label"
						suffixIcon={<i className="ant-menu-submenu-arrow" />}
					>
						{documentsState.statuses.map(({ id, name, color = null }) => (
							<Option key={id} name={name} value={id} label={<Tag color={color}>{name}</Tag>}>
								<Tag color={color}>{name}</Tag>
							</Option>
						))}
					</Select>
				</Form.Item>

				<Form.Item label="Сумма, руб">
					<div className="form-link-editor" style={{ display: 'flex' }}>
						<Input
							value={formState.total}
							onChange={(e) => {
								// /^[0-9 ]+$/.test(e.target.value) &&
								// ^$|^string(,string)*$
								setFormState((prev) => ({ ...prev, total: e.target.value }));
							}}
						/>
					</div>
				</Form.Item>
				<Form.Item label="Дата документа:">
					<DatePicker
						style={{ width: '100%' }}
						value={!!formState.date ? moment(formState.date) : null}
						format={'YYYY-MM-DD'}
						className={clicked && !formState.date && 'error-field'}
						locale={locale}
						onChange={(value) => {
							setFormState((prev) => ({
								...prev,
								date: Date.parse(value),
							}));
						}}
					/>
					{clicked && !formState.date && <span className="error-form-control">Выберите дату документа</span>}
				</Form.Item>
				<Form.Item label="Контрагент:">
					<Select
						onChange={(value) => {
							setFormState((prev) => ({ ...prev, contragentId: value }));
						}}
						showSearch
						optionFilterProp="children"
						filterOption={selectFilterOption}
						value={formState.contragentId}
						optionLabelProp="label"
						suffixIcon={<i className="ant-menu-submenu-arrow" />}
					>
						{documentsState.contragents.map(({ id, name, color = null }) => (
							<Option key={id} name={name} value={id} label={<Tag color={color}>{name}</Tag>}>
								<Tag color={color}>{name}</Tag>
							</Option>
						))}
					</Select>
					{contragentsControl && <span className="error-form-control">Выберите контрагента</span>}
				</Form.Item>

				<Form.Item label="Ссылка на документ:" style={{ marginLeft: 'auto' }}>
					<div className="form-link-editor" style={{ display: 'flex' }}>
						<Input
							value={formState.link}
							onChange={(e) => {
								setFormState((prev) => ({ ...prev, link: e.target.value }));
							}}
						/>
						<a
							href={formState.link}
							style={{
								pointerEvents: formState.link.trim() ? '' : 'none',
								opacity: formState.link.trim() ? '1' : '0.6',
							}}
							target="_blank"
							rel="noreferrer"
						>
							<LinkOutlined />
						</a>
					</div>
				</Form.Item>
				<Form.Item label="Комментарий:">
					<TextArea
						value={formState.comment}
						className="form-comment-editor"
						onChange={(e) => {
							setFormState((prev) => ({ ...prev, comment: e.target.value }));
						}}
					/>
				</Form.Item>

				<Form.Item className="ant-btn--wrapper">
					<Button onClick={closeModal} style={{ width: '31.5%' }}>
						Отмена
					</Button>
					<Button
						type="primary"
						style={{ width: '31.5%' }}
						htmlType="submit"
						loading={loading}
						disabled={loading}
					>
						Сохранить
					</Button>
				</Form.Item>
			</Form>
		</Modal>
	);
};
