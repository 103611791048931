import { PlusSquareOutlined } from '@ant-design/icons';
import { Button, Card, Form, DatePicker, Select, Tag, Input, Divider } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { selectFilterOption } from '@services/utils';

import { updateDocumentsParamsAC } from '@store/reducers/documents';
import 'moment/locale/ru';
import locale from 'antd/es/date-picker/locale/ru_RU';

const { Option } = Select;
export const DocumentsSidebar = ({ createTableRow, updateLimit, documentsState, closeFiltersModal = () => {} }) => {
	const [form] = Form.useForm();
	const dispatch = useDispatch();

	const { contragents, companies, types, statuses } = documentsState;

	const initialFilterState = {
		startDate: null,
		endDate: null,
		type: null,
		status: null,
		contragentId: null,
		name: null,
		companyId: null,
	};
	let [filter, setFilter] = useState(initialFilterState);

	let [nameType, setNameType] = useState('includes');
	let [filterName, setFilterName] = useState('');
	let [startDate, setStartDate] = useState(null);
	let [endDate, setEndDate] = useState(null);

	const updateName = (value) => {
		switch (nameType) {
			case 'startsWith':
				return `${value}%`;
			case 'endsWith':
				return `%${value}`;
			default:
				return `%${value}%`;
		}
	};

	const applyFilterOptions = () => {
		const options = Object.keys(filter).filter((key) => filter[key] !== null);
		const filteredOptions = options.reduce((obj, key) => {
			obj[key] = filter[key];
			return obj;
		}, {});
		options.length === 0 ? clearFilters() : dispatch(updateDocumentsParamsAC('filter', filteredOptions));
		updateLimit();
		window.innerWidth <= 1300 && closeFiltersModal();
	};

	const clearFilters = () => {
		setFilter((prev) => ({ ...prev, ...initialFilterState }));
		setNameType('includes');
		setFilterName('');
		dispatch(updateDocumentsParamsAC('filter', null));
	};

	const disabledDate = (startDate, endDate, dateKey) => (current) => {
		const endDateDisable = {
			start: current && endDate && Date.parse(current) > Date.parse(endDate),
			end: current && startDate && Date.parse(current) < Date.parse(startDate),
		};
		return endDateDisable[dateKey];
	};

	useEffect(() => {
		setFilter((prev) => ({
			...prev,
			name: !!filterName && filterName.trim() ? updateName(filterName) : null,
		}));
	}, [nameType]);

	return (
		<Card id="table-sidebar">
			<Form layout="vertical" form={form} onFinish={applyFilterOptions}>
				<Form.Item>
					<Button type="primary" onClick={createTableRow} disabled={contragents.length === 0}>
						<PlusSquareOutlined />
						<span>Создать документ</span>
					</Button>
				</Form.Item>
				{contragents.length > 0 && (
					<>
						<Divider />
						<h3>Фильтрация</h3>
						<Form.Item label="По компании:">
							<Select
								onChange={(value) => {
									setFilter((prev) => ({ ...prev, companyId: value }));
								}}
								optionFilterProp="children"
								filterOption={selectFilterOption}
								value={filter.companyId}
								optionLabelProp="label"
								suffixIcon={<i className="ant-menu-submenu-arrow" />}
							>
								{companies.map(({ id, name, color = null }) => (
									<Option key={id} name={name} value={id} label={<Tag color={color}>{name}</Tag>}>
										<Tag color={color}>{name}</Tag>
									</Option>
								))}
							</Select>
						</Form.Item>
						<Form.Item label="По названию документа:">
							<Select
								onChange={(value) => setNameType(value)}
								value={nameType}
								className="select-before"
								suffixIcon={<i className="ant-menu-submenu-arrow" />}
							>
								<Option value="startsWith">
									<Tag>Начинается с</Tag>
								</Option>
								<Option value="endsWith">
									<Tag>Заканчивается на</Tag>
								</Option>
								<Option value="includes">
									<Tag>Включает</Tag>
								</Option>
							</Select>

							<Input
								value={filterName}
								className="name-filter-input"
								onChange={(e) => {
									setFilterName(e.target.value);
									setFilter((prev) => ({
										...prev,
										name: e.target.value.trim() ? updateName(e.target.value) : null,
									}));
								}}
								placeholder="Введите название документа"
							/>
						</Form.Item>
						<Form.Item label="По дате документа:">
							<div className="wrapper">
								<span>от:</span>
								<DatePicker
									format={'YYYY-MM-DD'}
									value={startDate}
									locale={locale}
									onChange={(value) => {
										setStartDate(value);
										setFilter((prev) => ({
											...prev,
											startDate: Date.parse(value),
											// startDate: new Date(value).toISOString(),
										}));
									}}
									disabledDate={disabledDate(startDate, endDate, 'start')}
								/>
							</div>
							<div className="wrapper">
								<span>до:</span>
								<DatePicker
									format={'YYYY-MM-DD'}
									value={endDate}
									locale={locale}
									onChange={(value) => {
										setEndDate(value);
										setFilter((prev) => ({
											...prev,
											// endDate: Date.parse(value + 1000 * 60 * 60 * 24 - 1),
											endDate: Date.parse(value),
										}));
									}}
									disabledDate={disabledDate(startDate, endDate, 'end')}
								/>
							</div>
						</Form.Item>
						<Form.Item label="По типу документа:">
							<Select
								onChange={(value) => {
									setFilter((prev) => ({ ...prev, type: value }));
								}}
								optionFilterProp="children"
								filterOption={selectFilterOption}
								value={filter.type}
								optionLabelProp="label"
								suffixIcon={<i className="ant-menu-submenu-arrow" />}
							>
								{types.map(({ id, name, color = null }) => (
									<Option key={id} name={name} value={id} label={<Tag color={color}>{name}</Tag>}>
										<Tag color={color}>{name}</Tag>
									</Option>
								))}
							</Select>
						</Form.Item>
						<Form.Item label="По статусу документа:">
							<Select
								onChange={(value) => {
									setFilter((prev) => ({ ...prev, status: value }));
								}}
								optionFilterProp="children"
								filterOption={selectFilterOption}
								value={filter.status}
								optionLabelProp="label"
								suffixIcon={<i className="ant-menu-submenu-arrow" />}
							>
								{statuses.map(({ id, name, color = null }) => (
									<Option key={id} name={name} value={id} label={<Tag color={color}>{name}</Tag>}>
										<Tag color={color}>{name}</Tag>
									</Option>
								))}
							</Select>
						</Form.Item>
						<Form.Item label="По контрагенту:">
							<Select
								onChange={(value) => {
									setFilter((prev) => ({ ...prev, contragentId: value }));
								}}
								showSearch
								optionFilterProp="children"
								filterOption={selectFilterOption}
								value={filter.contragentId}
								optionLabelProp="label"
								suffixIcon={<i className="ant-menu-submenu-arrow" />}
							>
								{contragents.map(({ id, name, color = null }) => (
									<Option key={id} name={name} value={id} label={<Tag color={color}>{name}</Tag>}>
										<Tag color={color}>{name}</Tag>
									</Option>
								))}
							</Select>
						</Form.Item>
						<Form.Item>
							<Button type="primary" htmlType="submit">
								<span>Применить фильтры</span>
							</Button>
						</Form.Item>
						<Form.Item>
							<Button type="primary" htmlType="reset" onClick={clearFilters}>
								<span>Очистить фильтры</span>
							</Button>
						</Form.Item>
					</>
				)}
				{contragents.length === 0 && <h3>Сначала создайте контрагента</h3>}
			</Form>
		</Card>
	);
};
